<template>
    <v-content class="loginContent">
        <v-container class="fill-height"
                     fluid>
            <v-row align="center"
                   justify="center">
                <v-col cols="12"
                       sm="8"
                       md="4">
                    <v-card class="elevation-12">
                        <v-toolbar color="#213B87"
                                   dark
                                   flat>
                            <v-toolbar-title>
                                <router-link to="/">
                                    <img src="../assets/images/WEB-BOT-EROVET.png" width="175" alt="logoerovet">
                                </router-link>
                            </v-toolbar-title>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card-text>
                          <v-alert
                              border="right"
                              colored-border
                              type="error"
                              v-if="emptyError"
                              elevation="2"
                          >
                            Please fill in all fields
                          </v-alert>
                          <v-alert
                              border="right"
                              colored-border
                              type="error"
                              v-if="notFoundError"
                              elevation="2"
                          >
                            E-Mail / Password does not exist
                          </v-alert>
                          <v-alert
                              border="right"
                              colored-border
                              type="error"
                              v-if="apiError"
                              elevation="2"
                          >
                            There was an error, please try again later
                          </v-alert>

                            <v-form @keyup.enter.native="submit">
                                <v-text-field
                                        label="E-Mail"
                                        name="login"
                                        v-model="email"
                                        prepend-icon="person"
                                        type="text"/>
                                <v-text-field
                                        v-model="password"
                                        id="password"
                                        label="Password"
                                        name="password"
                                        prepend-icon="lock"
                                        type="password"/>
                                <router-link to="/forgot">
                                  <span style="color:rgb(33, 59, 135); font-weight: bold">Forgot your password?</span>
                                </router-link>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <router-link to="register">
                                <v-btn color="#213B87" class="loginBT">Register</v-btn>
                            </router-link>
                            <v-btn color="#213B87" class="loginBT" :loading="loading" v-on:click="submit">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                loading: false,
                emptyError: false,
                apiError: false,
                notFoundError: false,
                email: '',
                password: ''
            }
        },
        mounted() {
            this.checkSession()
        },
        methods: {
            checkSession() {
                this.logged = localStorage.token != null;
                if (!this.logged) this.$router.push('/login');
            },
            signOut() {
                localStorage.clear();
                location.reload();
            },
            submit() {
                this.loading = true;
                if (this.email === '' || this.password === '') {
                    this.emptyError = true;
                    this.loading = false;
                } else {
                    this.emptyError = false;
                    let url = this.base_url + '/home/login';
                    let formData = new FormData();
                    formData.set('email', this.email);
                    formData.set('password', this.password);
                    this.axios.post(url, formData).then(response => {
                        localStorage.token = response.data.token;
                        localStorage.rol = response.data.rol;
                        if (response.data.rol != 4)
                            this.$router.push('/dashboard/home');
                        else
                            this.$router.push('/');
                        location.reload();
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        if (error.response.status === 404) {
                            this.notFoundError = true;
                            this.apiError = false;
                        } else {
                            this.notFoundError = false;
                            this.apiError = true;
                        }
                    })
                }
            }
        }
    };
</script>
<style>
    .loginBT {
        font-size: 12pt !important;
        margin: 0 0.5em 0 0.5em;
        padding: 0.6em !important;
    }

    .emptyAlert {
        background: #9D130B !important;
    }
</style>
